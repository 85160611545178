import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Error from '../pages/Error'
import Home from '../pages/Home'
import React from 'react';
import TermsOfService from '../pages/Terms'

const Routes = () => {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" render={() => <Home />} />
                    <Route exact path="/terms-of-service" render={() => <TermsOfService />} />
                    <Route component={Error} />
                </Switch>
            </BrowserRouter>
        )
}

export default Routes

