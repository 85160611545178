import Footer from '../components/Footer'
import Header from '../components/HeaderSmall'
import React from 'react';

class PrivacyPolicy extends React.Component {

    render() {
        return (<> <Header /> < div className="bg-white" > <div className="container">
            <div
                style={{
                    paddingTop: 100,
                    paddingBottom: 200
                }}>
                <h3><span style={{ fontWeight: 400 }}>Algemene Voorwaarden</span></h3>
                <p><span style={{ fontWeight: 400 }}>Deze algemene voorwaarden zijn van toepassing op alle diensten die worden geleverd op </span><a href="http://www.methods.digital"><span style={{ fontWeight: 400 }}>www.methods.digital</span></a><span style={{ fontWeight: 400 }}> door methods, een eenmans zaak gevestigd op luikerwijk 4, 3600 Genk. Gekend bij de kruispuntbank van ondernemingen onder het nummer BE0743.423.341.</span></p>
                <p><span style={{ fontWeight: 400 }}>Deze huidige voorwaarden zijn definitief en volledig en vervangen alle mondelinge of schriftelijke eerdere mededelingen, verklaringen van voorwaarden tussen methods en opdrachtgever.</span></p>
                <h4><span style={{ fontWeight: 400 }}>Definities</span></h4>
                <ol>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Opdrachtgever: de natuurlijke of rechtspersoon met wie de overeenkomst tot levering van producten en diensten van methods wordt gesloten.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Website: een samenhangend geheel van digitale internetpagina's inclusief eventuele bijbehorende digitale afbeeldingen, scripts, databases en andere media.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Overeenkomst: elke wederzijdse acceptatie, schriftelijk of per e-mail bevestigd, van levering van één of meer producten of diensten van methods.</span></li>
                </ol>
                <p><span style={{ fontWeight: 400 }}>Tussen methods en de opdrachtgever komt er een overeenkomst voor het afnemen van een dienst tot stand. Door het sluiten van de overeenkomst en het betalen van een prijs, verleent methods de dienst die de opdrachtgever heeft gekozen. Deze dienst kan bestaan uit: websiteontwikkeling en webdesign, drukwerk, ontwerp van logo of huisstijl, opstellen van nieuwsbrieven waarbij telkens de opdrachtgever verantwoordelijk blijft voor de inhoud van de website.</span></p>
                <h4><span style={{ fontWeight: 400 }}>Toepasselijkheid</span></h4>
                <ol>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Deze algemene voorwaarden zijn van toepassing op alle aanbiedingen, offertes, alle door methods gesloten overeenkomsten, verrichtte diensten en alle overige door methods verrichtte handelingen.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Door ondertekening van een overeenkomst met methods verklaart de opdrachtgever dat hij kennis heeft genomen van deze algemene voorwaarden van methods en akkoord gaat met de inhoud ervan als bindend.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Afwijken van de algemene leveringsvoorwaarden is alleen dan mogelijk, wanneer die expliciet in een overeenkomst tussen de opdrachtgever en methods opgenomen wordt.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Deze algemene voorwaarden zijn eveneens ter inzage op de maatschappelijke zetel van methods.</span></li>
                </ol>
                <h4><span style={{ fontWeight: 400 }}>Aanbieding &amp; Acceptatie</span></h4>
                <ol>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Alle offertes en prijsopgaven door of vanwege methods gedaan zijn vrijblijvend, behalve indien door methods schriftelijk of per e-mail anders is vermeld of deze ondertekend is door opdrachtgever.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Offertes en prijsopgaven door methods blijven 1 maand geldig, tenzij een andere termijn wordt vermeld door methods. Facturering geschiedt op basis van deze offerte.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Wijzigingen in de oorspronkelijk gesloten overeenkomst tussen de opdrachtgever en methods zijn pas geldig vanaf het moment dat deze wijzigingen middels een aanvullend of gewijzigde overeenkomst zijn aanvaard door beide partijen.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>De opdrachtgever erkent onderling e-mailverkeer als een wettelijk geldig bewijsmiddel.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Aanbiedingen of offertes gelden niet automatisch voor de toekomstige opdrachten.</span></li>
                </ol>
                <h4><span style={{ fontWeight: 400 }}>Aanvang van de overeenkomst</span></h4>
                <ol>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Een overeenkomst komt tot stand op de dag dat methods het door opdrachtgever ondertekende offerte/contract formulier ontvangen heeft.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Aanvullingen en wijzigingen van de overeenkomst kunnen uitsluitend schriftelijk geschieden.</span></li>
                </ol>
                <h4><span style={{ fontWeight: 400 }}>Uitvoering van de overeenkomst</span></h4>
                <h5><span style={{ fontWeight: 400 }}>VERPLICHTINGEN VAN METHODS</span></h5>
                <ol>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>methods zal de overeenkomst naar beste inzicht en vermogen en overeenkomstig de eisen van goed vakmanschap uitvoeren.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Indien en voor zover een goede uitvoering van de overeenkomst dit vereist, heeft methods het recht bepaalde werkzaamheden te laten verrichten door derden.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>methods maakt gebruik van ReactJS, Wordpress, Shopify en verschillende andere frameworks. methods verplicht zich ertoe de overeenkomst naar best vermogen uit te voeren en kan in geen geval aansprakelijk zijn voor vertragingen in de uitvoering van de diensten of de werking van de website die voortvloeien uit onder andere het gebruik van verschillende plug ins.</span></li>
                </ol>
                <p><span style={{ fontWeight: 400 }}>methods kan geen garantie geven voor de (in)compatibiliteit van verschillende plug-ins en zal bij problemen op de website zich ertoe verplichten te handelen naar best vermogen en deze op te lossen na melding per e-mail door opdrachtgever:</span></p>
                <ul>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>binnen 24 uur bij kleine fouten op de website (zoals o.a., maar niet-limitatief: een verkeerde datum, typfout,...)</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>binnen 48 uur bij een onwerkbare website</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>naar best vermogen en zo snel mogelijk bij een verhindering van het gebruik van de website.</span></li>
                </ul>
                <h5><span style={{ fontWeight: 400 }}>VERPLICHTINGEN VAN OPDRACHTGEVER</span></h5>
                <ol>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>De opdrachtgever draagt er zorg voor dat alle gegevens, waarvan methods aangeeft dat deze noodzakelijk zijn of waarvan de opdrachtgever redelijkerwijs behoort te begrijpen dat deze noodzakelijk zijn voor het uitvoeren van de overeenkomst tijdig, binnen 14 dagen vanaf een verzoek van methods (per e-mail,...) , aan methods worden verstrekt.</span><span style={{ fontWeight: 400 }}><br /></span><span style={{ fontWeight: 400 }}>Indien de benodigde gegevens niet tijdig aan methods zijn verstrekt, heeft methods het recht de uitvoering van de overeenkomst op te schorten en/of de uit de vertraging voortvloeiende extra kosten volgens de gebruikelijke tarieven aan de opdrachtgever in rekening te brengen. methods kan in geen geval aansprakelijk zijn voor welke schade dan ook die voortkomen uit het laattijdig (zie termijn voorgaande alinea) verstrekken van gegevens door de opdrachtgever.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>methods is niet aansprakelijk voor schade, van welke aard ook, doordat methods is uitgegaan van door de opdrachtgever verstrekte onjuiste en/of onvolledige gegevens, tenzij deze onjuistheid of onvolledigheid voor methods kenbaar behoorde te zijn.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Indien is overeengekomen dat de overeenkomst in fasen zal worden uitgevoerd, kan methods de uitvoering van die onderdelen die tot een volgende fase behoren opschorten totdat de opdrachtgever de resultaten van de daaraan voorafgaande fase schriftelijk heeft goedgekeurd.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Indien door methods of door opdrachtgever ingeschakelde derden in het kader van de opdracht werkzaamheden worden verricht op de locatie van de opdrachtgever of een door opdrachtgever aangewezen locatie, draagt de opdrachtgever kosteloos zorg voor de door die medewerkers in redelijkheid gewenste faciliteiten.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Opdrachtgever vrijwaart methods voor eventuele aanspraken van derden, die in verband met de uitvoering van de overeenkomst schade lijden en welke aan opdrachtgever toerekenbaar is.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>De opdrachtgever verplicht zich ertoe:</span></li>
                </ol>
                <ul>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>alle gegevens die noodzakelijk zijn voor de uitvoering van de dienst tijdig, binnen 14 dagen na verzoek door methods (per e-mail,...)&nbsp; te verstrekken aan methods</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>updates en upgrades toe te passen op verouderde websites binnen een termijn van 60 dagen</span></li>
                </ul>
                <p><span style={{ fontWeight: 400 }}>Indien de opdrachtgever de voorgestelde updates en upgrades niet uitvoert binnen een termijn van 60 dagen, behoudt methods het recht voor om de website onbruikbaar te maken of zelf de updates uit te voeren mits betaling door de opdrachtgever. Bij een niet-uitvoering van de updates en upgrades door de opdrachtgever zelf of een niet-betaling van de factuur behoudt methods het recht om de overeenkomst te be&euml;indigen met inachtneming van een opzegtermijn van 4 maanden.</span></p>
                <h4><span style={{ fontWeight: 400 }}>Duur en Beëindiging</span></h4>
                <ol>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Het ontwerpen van een nieuwe website is een dienst die methods levert aan de opdrachtgever waarbij de levertijd mondeling of schriftelijk wordt afgesproken en afhankelijk is van de noodzakelijke gegevens die al dan niet tijdig aan methods worden verstrekt.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Een onderhoudsovereenkomst met betrekking tot een bestaande website tussen methods en de opdrachtgever kent een minimale looptijd van 12 maanden en wordt iedere 12 maanden stilzwijgend verlengd met 12 maanden. Na het verstrijken van de minimale looptijd kan de overeenkomst op elk gewenst moment schriftelijk worden opgezegd, met inachtneming van een opzegtermijn van 2 maanden.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>methods heeft het recht de overeenkomst(en) zonder ingebrekestelling of gerechtelijke tussenkomst met onmiddellijke ingang te beëindigen indien de opdrachtgever zich niet, onbehoorlijk of onvolledig houdt aan de met methods overeenkomst(en) inclusief de bijbehorende leveringsvoorwaarden.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>methods heeft het recht de overeenkomst zonder ingebrekestelling of gerechtelijke tussenkomst met onmiddellijke ingang te beëindigen indien opdrachtgever in staat van faillissement is verklaard, elke vorm van uitstel van betaling heeft aangevraagd of verkregen of anderszins het vrije beheer of zijn vermogen heeft verloren. Laatstgenoemde partij heeft alsdan geen recht op enige schadevergoeding.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>methods heeft het recht de overeenkomst(en) zonder ingebrekestelling of gerechtelijke tussenkomst met onmiddellijke ingang te beëindigen indien blijkt dat opdrachtgever intolerante, haatzaaiende, ophitsende, discriminerende of pornografische teksten, afbeeldingen, banners of hyperlinks naar websites met intolerante, haatzaaiende, ophitsende, discriminerende of pornografische inhoud, op de website plaatst of wil plaatsen.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>methods heeft het recht de overeenkomst zonder ingebrekestelling of gerechtelijke tussenkomst binnen een termijn van 4 maanden te beëindigen indien de opdrachtgever de updates en upgrades niet uitvoert of methods niet betaalt voor de updates en upgrades die methods kan uitvoeren.</span></li>
                </ol>
                <h4><span style={{ fontWeight: 400 }}>Levering en leveringstijd</span></h4>
                <ol>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>methods gaat na ontvangst van de benodigde gegevens, teksten en/of beeldmateriaal zo spoedig mogelijk van start met het aanmaken van de overeengekomen producten en/of diensten en deelt de opdrachtgever de verwachte levertijd mee.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Bij het ontwerpen van een nieuwe website maakt methods eerst een basisontwerp of laat deze door derden maken en legt dit ter goedkeuring voor aan de opdrachtgever. Laatstgenoemde controleert dit ontwerp en geeft commentaar, opmerkingen of aanmerkingen zo spoedig mogelijk door aan methods. Wanneer de opdrachtgever niet binnen een redelijke termijn reageert op het basisontwerp, gaat methods ervan uit dat de opdrachtgever akkoord gaat met het basisontwerp. Na ontvangst van de reacties van de opdrachtgever op het basisontwerp of het binnen een redelijke termijn uitblijven van zulke reacties gaat methods over tot het voltooien van de volledige website.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Mocht methods onverhoopt niet in staat zijn binnen de overeengekomen levertijd aan zijn verplichtingen te voldoen, kan methods alleen schriftelijk in gebreke worden gesteld, waarbij methods een termijn van minimaal 14 dagen gegund wordt om haar verplichtingen na te komen.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Door methods gemaakte websites worden online en actief geplaatst.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Opdrachtgevers hebben na oplevering van de website recht op een vrijblijvend onderhoudscontract.</span></li>
                </ol>
                <h4><span style={{ fontWeight: 400 }}>Overmacht</span></h4>
                <ol>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>methods is niet aansprakelijk voor niet-nakoming of vertraging bij de uitvoering van haar verplichtingen of voor enige schade, indien dit het gevolg is van overmacht zoals brand, natuurramp of enige andere oorzaak die buiten de controle valt van methods.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>In het geval dat de overmacht slechts tijdelijk van aard is, zal methods alsnog aan alle verplichtingen trachten te voldoen vanaf het moment dat dit redelijkerwijs weer mogelijk is. Wanneer in onderling overleg tussen de opdrachtgever en methods geconcludeerd wordt dat dit niet meer mogelijk is, zal de overeenkomst in onderling overleg herzien of ontbonden worden. Eventuele reeds geleverde prestaties door methods tot aan het moment van overmacht zullen alsnog gefactureerd worden.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>methods is niet aansprakelijk voor de gevolgen van gebeurtenissen bij een hostingprovider, domeinnaamregistrant of anderen waarop methods geen invloed kan uitoefenen.</span></li>
                </ol>
                <h4><span style={{ fontWeight: 400 }}>Prijzen</span></h4>
                <ol>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Alle genoemde prijzen, alsmede overeengekomen prijzen zijn in euro en exclusief BTW, tenzij anders is vermeld.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>methods heeft het recht de tarieven te wijzigen. Deze wijzigingen worden uiterlijk 1 maand voordat deze ingaan aan opdrachtgever bekend gemaakt. Opdrachtgever is gerechtigd de overeenkomst te beëindigen tegen de datum van inwerkingtreding van de wijziging.</span></li>
                </ol>
                <h4><span style={{ fontWeight: 400 }}>Betalingsvoorwaarden</span></h4>
                <ol>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Na ondertekening van de offerte door opdrachtgever krijgt de offerte de status van contract. Vanaf dat moment is er een overeenkomst voor het bouwen van een website gesloten tussen de opdrachtgever en methods en is de opdrachtgever betalingsverplicht.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>De opdrachtgever dient 50% van het totaalbedrag van de offerte als aanbetaling te voldoen. Aanbetaling dient plaats te vinden binnen 14 dagen na ontvangst van de voorschotfactuur op het vermelde bankrekeningnummer met de gestructureerde mededeling zoals vermeld op de factuur. methods behoudt zich het recht voor om met haar werkzaamheden te beginnen nadat de aanbetaling is ontvangen. De website komt op een tijdelijke plaats op internet staan totdat deze gereed is. Na het gereedkomen van de website wordt dit aangeboden voor oplevering aan de opdrachtgever. Vervolgens heeft de opdrachtgever de mogelijkheid om binnen een korte termijn opmerkingen te geven waarbij 5% van het gefactureerd bedrag wordt ingehouden.</span><span style={{ fontWeight: 400 }}><br /></span><span style={{ fontWeight: 400 }}>Bij weigering tot oplevering gedurende een bepaalde periode die niet te wijten is aan methods, wordt ook 5% van het gefactureerd bedrag opeisbaar.</span><span style={{ fontWeight: 400 }}><br /></span><span style={{ fontWeight: 400 }}>De opdrachtgever dient na oplevering van de website het resterend verschuldigd bedrag te voldoen.&nbsp; methods stuurt hiertoe een factuur welke binnen 14 dagen na factuurdatum dient te worden voldaan. Na ontvangst van het bedrag wordt de website op de definitieve locatie op het internet geplaatst.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Een maand na facturatie wordt de website als volledig beschouwd en worden verdere veranderingen aan de website niet meer kosteloos uitgevoerd. Alle veranderingen nadien vallen onder het onderhoudscontract.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>In genoemde gevallen behoudt methods zich het recht voor eventuele lopende werkzaamheden of geleverde diensten tijdelijk te staken, totdat de verschuldigde bedragen zijn ontvangen.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Vanaf het moment dat de opdrachtgever de betalingstermijn (14 dagen na de factuurdatum, tenzij uitdrukkelijk anders overeengekomen) overschrijdt, is hij zonder een daartoe strekkende ingebrekestelling in gebreke.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Indien de betalingstermijn is overschreden, wordt door methods een aanmaning tot betaling verstuurd. Kosten van een aanmaning, t.w. netto &euro; 25,00 wordt in rekening gebracht bij de opdrachtgever.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Indien de betalingstermijn met 28 dagen is overschreden, wordt door methods een tweede aanmaning tot betaling verstuurd. Kosten van een aanmaning, t.w. netto &euro; 50, wordt in rekening gebracht bij de opdrachtgever.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Wanneer de opdrachtgever binnen 28 dagen nog niet aan zijn verplichtingen heeft voldaan, is de opdrachtgever vanaf dit moment de wettelijke rente plus buitengerechtelijke incassokosten verschuldigd over het openstaande factuurbedrag, berekend volgens het geadviseerde (gestaffelde) incassotarief.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Mocht het gefactureerde bedrag in de ogen van de opdrachtgever onjuist zijn, dan dient hij terstond, doch uiterlijk binnen 14 dagen na facturering methods hiervan schriftelijk op de hoogte te stellen. Laatstgenoemde zal deze bewering onderzoeken en indien nodig een nieuwe factuur verzenden, die binnen 14 dagen na verzending voldaan moet worden.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Indien methods abusievelijk een groter bedrag dan het gefactureerde bedrag ontvangt, zal methods het teveel ontvangen bedrag direct na eigen ontdekking of op het eerste verzoek van de opdrachtgever terugstorten op de rekening van de opdrachtgever.</span></li>
                </ol>
                <h4><span style={{ fontWeight: 400 }}>Onderhoudscontracten</span></h4>
                <ol>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Deze voorwaarden gelden voor een &eacute;&eacute;njarig onderhoudscontract voor een bestaande website.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Met onderhoud van de website wordt bedoeld het toevoegen, verwijderen en/of wijzigen van inhoudelijke onderdelen, door methods of de opdrachtgever vervaardigd, en niet door derden.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Bij een onderhoudsovereenkomst voor een website wordt door methods aan de opdrachtgever een factuur gestuurd. De opdrachtgever dient binnen 14 dagen na het verzenden van de factuur door methods het verschuldigde bedrag te voldoen.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>De opzegtermijn voor een onderhoudscontract is twee maanden voor het einde van de contractdatum.</span></li>
                </ol>
                <h4><span style={{ fontWeight: 400 }}>Intellectuele eigendom</span></h4>
                <ol>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Al het door methods vervaardigde materiaal is beschermd door intellectuele eigendomsrechten en mag zonder de uitdrukkelijke toestemming van methods niet worden bewerkt of verwerkt in andere websites dan waarvoor het oorspronkelijk gemaakt is. Onderhoud door derden van door methods gemaakte websites is slechts toegestaan wanneer de door methods vervaardigde onderdelen worden gebruikt in de website van het bedrijf dat bij methods een contract is aangegaan.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Het eigendom van door methods verstrekte ideeën, concepten of (proef)-ontwerpen blijft volledig bij methods, tenzij uitdrukkelijk schriftelijk anders overeengekomen wordt. Indien methods en opdrachtgever overeenkomen de ontwerpen over te dragen, dient dit te gebeuren tegen een vergoeding en behoudt methods ten alle tijde het recht om de ontwerpen/bepaalde elementen te hergebruiken.</span><span style={{ fontWeight: 400 }}><br /></span><span style={{ fontWeight: 400 }}>Bij een inbreuk op een intellectueel eigendomsrecht, staat het methods vrij om een zelf vast te stellen redelijke vergoeding in rekening te brengen.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>methods behoudt zich het recht voor de ontwerpen, teksten, lay-out website,.. voor andere doeleinden te gebruiken, voorzover hierbij geen vertrouwelijke informatie ter kennis van derden wordt gebracht.</span></li>
                </ol>
                <h4><span style={{ fontWeight: 400 }}>Aansprakelijkheid</span></h4>
                <ol>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Indien methods afhankelijk is van de medewerking, diensten en leveringen van derden, waarop methods weinig of geen invloed kan uitoefenen, kan methods enkel aansprakelijk worden gesteld voor schade die voortkomen uit deze relaties, het verbreken ervan voor een maximaal bedrag van 25,00 euro, enkel voor schade die ontstaat of zichtbaar wordt gedurende de relatie met methods.</span><span style={{ fontWeight: 400 }}><br /></span><span style={{ fontWeight: 400 }}>Indien methods bij haar activiteiten afhankelijk is van de medewerking van de opdrachtgever, kan methods op geen enkele manier aansprakelijk worden gesteld voor welke schade dan ook.</span><span style={{ fontWeight: 400 }}><br /></span><span style={{ fontWeight: 400 }}>methods is verplicht de problemen aan de website van de opdrachtgever naar best vermogen op te lossen.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>In het geval van toerekenbare tekortkoming in de nakoming van de overeenkomst is methods slechts aansprakelijk voor vervangende schadevergoeding, d.w.z. vergoeding van de waarde van de achterwege gebleven prestatie. Iedere aansprakelijkheid van methods voor enige andere vorm van schade is uitgesloten, daaronder begrepen aanvullende schadevergoeding in welke vorm dan ook, vergoeding van indirecte schade of gevolgschade of schade wegens gederfde omzet of winst.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>De opdrachtgever vrijwaart methods voor alle aanspraken op schadevergoeding die derden mochten doen gelden terzake van schade die op enigerlei wijze is ontstaan door het onrechtmatig, dan wel onzorgvuldig gebruik van de aan de opdrachtgever geleverde producten en diensten van methods.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Gezien het op het Internet grote aantal knooppunten met menselijke tussenkomst, het gebruik van lokale netwerken en draadloze communicatie, moet men rekening houden met het feit dat de informatie verkregen of verzonden via het Internet vrij toegankelijk is.</span><span style={{ fontWeight: 400 }}><br /></span><span style={{ fontWeight: 400 }}>methods die onder de Algemene Verordening Gegevensbescherming 2016/679 handelt als verwerker is aansprakelijk voor schade die door zijn verwerking is veroorzaakt indien de verwerker in strijd handelt met de instructies van de verwerkingsverantwoordelijke. methods is onderling verantwoordelijk met de opdrachtgever die handelt als verwerkingsverantwoordelijke en die volledig instaat voor de schade veroorzaakt door het verzenden van vertrouwelijke of geheime informatie. methods is niet aansprakelijk voor beveiliging of misbruik door derden van de gegevens die worden opgeslagen.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>methods is niet verantwoordelijk of aansprakelijk voor de inhoud van het door de opdrachtgever aangeleverde materiaal dat op de website van de opdrachtgever is aangebracht.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>De inhoud van de gegevensverspreiding en &ndash;publicatie blijft in alle gevallen de eigen verantwoordelijkheid van de opdrachtgever. methods wordt niet geacht deze te beperken of erop toe te zien, noch kan methods aansprakelijk worden gesteld voor de inhoud van de eigen publicatie. Alle gevolgen van intellectuele eigendomsrechten (o.a. auteursrechten), vergoedingen, onkosten of boetes zijn ten laste van de opdrachtgever.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>De opdrachtgever is aansprakelijk voor alle schade die methods mocht lijden ten gevolge van een aan de opdrachtgever toerekenbare tekortkoming in de nakoming van de verplichtingen voortvloeiende uit de overeenkomst en deze voorwaarden.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Wijzigingen in de gegevens van de opdrachtgever dient deze direct schriftelijk mede te delen aan methods. Als de opdrachtgever dit niet doet, is deze aansprakelijk voor eventuele gevolgschade die methods daardoor lijdt.</span></li>
                </ol>
                <h4><span style={{ fontWeight: 400 }}>Overdracht van rechten en verplichtingen</span></h4>
                <ol>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>methods noch de opdrachtgever zijn gerechtigd hun rechten of plichten voortvloeiend uit gesloten overeenkomsten over te dragen aan een derde partij zonder voorafgaande schriftelijke toestemming van de wederpartij.</span></li>
                </ol>
                <h4><span style={{ fontWeight: 400 }}>Reclamatie</span></h4>
                <ol>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>De opdrachtgever heeft gedurende 14 dagen na levering de gelegenheid duidelijk omschreven gebreken aan de geleverde producten te melden aan methods, waarna methods deze gebreken zal trachten te verhelpen naar best vermogen. Wanneer aan methods binnen de genoemde termijn geen gebreken gemeld worden, vervalt elke mogelijkheid tot reclamatie. Door de opdrachtgever verstrekte onjuiste informatie die leidt tot gebreken in het geleverde wordt nadrukkelijk niet verstaan onder &ldquo;gebreken&rdquo;.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Reclamatie schort de verplichtingen van de opdrachtgever niet op.</span></li>
                </ol>
                <h4><span style={{ fontWeight: 400 }}>Buitengebruikstelling</span></h4>
                <ol>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>methods heeft het recht geleverde producten en diensten (tijdelijk) buiten gebruik te stellen en/of het gebruik ervan te beperken indien de opdrachtgever ter zake van de overeenkomst een verplichting jegens methods niet nakomt dan wel in strijd handelt met deze algemene voorwaarden. methods zal de opdrachtgever hiervan tevoren in kennis stellen, tenzij zulks in redelijkheid niet van methods kan worden verlangd. De verplichting tot betaling van de verschuldigde bedragen blijft ook tijdens de buitengebruikstelling bestaan.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Tot indienststelling wordt weer overgegaan indien de opdrachtgever binnen een door methods gestelde termijn zijn verplichtingen is nagekomen en een terzake vastgesteld bedrag voor herindienststelling heeft voldaan.</span></li>
                </ol>
                <h4><span style={{ fontWeight: 400 }}>Wijziging van de voorwaarden</span></h4>
                <ol>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>methods behoudt zich het recht voor deze voorwaarden te wijzigen of aan te vullen. De nieuwste versie van de algemene voorwaarden is steeds te raadplegen op de website van methods.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Wijzigingen gelden ook ten aanzien van reeds gesloten overeenkomsten met inachtneming van een termijn van 30 dagen na schriftelijke bekendmaking van de wijziging.</span></li>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Indien de opdrachtgever een wijziging in deze voorwaarden niet wil accepteren, dient hij dit schriftelijk mee te delen aan methods en kan hij tot de datum waarop de nieuwe voorwaarden van kracht worden de overeenkomst ontbinden tegen deze datum of op de ontvangstdatum van de opzegging indien deze na de ingangsdatum van de wijziging is.</span></li>
                </ol>
                <h4><span style={{ fontWeight: 400 }}>Deelbaarheid van de voorwaarden</span></h4>
                <ol>
                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Indien bij gerechtelijke uitspraak &eacute;&eacute;n of meer artikelen van deze voorwaarden ongeldig worden verklaard, zullen overige bepalingen van deze algemene voorwaarden volledig van kracht blijven en zullen methods en de opdrachtgever in overleg treden teneinde nieuwe bepalingen ter vervanging van de nietige, dan wel vernietigde bepalingen overeen te komen, waarbij zoveel mogelijk het doel en strekking van de nietige, dan wel vernietigde bepalingen in acht worden genomen.</span></li>
                </ol>
                <h4><span style={{ fontWeight: 400 }}>Geschillenregeling en toepasselijk recht</span></h4>
                <p><span style={{ fontWeight: 400 }}>Deze voorwaarden worden beheerst en ge&iuml;nterpreteerd volgens de Belgische wetgeving. Alleen de rechtbanken van het arrondissement waar de natuurpersoon die handeld bij methods gevestigd is, zijn bevoegd.</span></p>
                <p><span style={{ fontWeight: 400 }}>Alvorens een procedure te starten, hebben methods en de opdrachtgever, mits overeenstemming de mogelijkheid om te kiezen voor een alternatieve geschillenbeslechting.</span></p>
                <p><span style={{ fontWeight: 400 }}>Indien de opdrachtgever een consument is, bestaat de mogelijkheid voor een online geschillenbeslechting bij het &ldquo;Online Dispute Resolution (ODR)-platform, beschikbaar via de volgende link:&nbsp; </span><a href="https://ec.europa.eu/odr"><span style={{ fontWeight: 400 }}>https://ec.europa.eu/odr</span></a><span style={{ fontWeight: 400 }}>.</span></p>
                <p>&nbsp;</p>
            </div>
        </div> </div>
            <Footer /> </>);
    }
}

export default PrivacyPolicy;
